import React from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
// import Campaigns from '../components/Campaigns';
// import SchoolBuildings from '../components/SchoolBuildings';
import ShowAboutCampaign from '../components/ShowAboutCampaign';
import ShowBookCampaign from '../components/ShowBookCampaign';
// import ShowSchoolBuildings from '../components/ShowSchoolBuildings';
import AllBooksCampaign from '../components/AllBooksCampaign';

const Home = () => {
  return (
    <div>
        <Navbar />
        <Banner />
        <ShowAboutCampaign />
        {/* <Campaigns /> */}
        <div className="py-5">
          <AllBooksCampaign />
        </div>
        <ShowBookCampaign />
        {/* <SchoolBuildings />
        <ShowSchoolBuildings /> */}
        <Footer />
    </div>
  )
}

export default Home
