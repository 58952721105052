import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import errorimg from '../images/errorimg.png';

const BookCampaignDetail = () => {
  const { id } = useParams();
  const [bookCampaign, setbookCampaign] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/getbookcampaigns/${id}`);
        const data = await response.json();
        if (response.ok) {
            setbookCampaign(data);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError('Failed to fetch Book Campaign');
      }
    };

    fetchBuilding();
  }, [id]);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!bookCampaign) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div key={bookCampaign._id} className="max-w-4xl mx-auto mt-10 px-4 pb-5 relative">
      {bookCampaign.received && (
        <span className="absolute top-0 left-0 bg-green-500 text-white text-base font-bold py-1 px-3 rounded-br-lg">
          Fulfilled
        </span>
      )}
      <h1 className="text-5xl font-bold mb-5 text-center">{bookCampaign.name}</h1>
      <img
        src={bookCampaign.picture ? `${process.env.REACT_APP_API_URL}/${bookCampaign.picture}` : errorimg}
        alt={bookCampaign.name}
        className="w-full h-96 object-contain mb-5"
      />
      <p className="text-gray-700 mb-4">{bookCampaign.description}</p>
      <p className="text-gray-700 mb-2"><strong>Target:</strong> {bookCampaign.quantity}</p>
      <p className="text-gray-700 mb-2"><strong>Received:</strong> {bookCampaign.received ? 'Yes' : 'No'}</p>
      <p className="text-gray-700 mb-2"><strong>By:</strong> {bookCampaign.userId?.firstname}</p>
      <br />
      <Link
        to={`/donatebook/${bookCampaign._id}`}
        className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none ${
          bookCampaign.received
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        }`}
        onClick={(e) => {
          if (bookCampaign.received) {
            e.preventDefault();
          }
        }}
      >
        Donate Book
        <svg
          className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg>
      </Link>
    </div>
  );
};

export default BookCampaignDetail;
