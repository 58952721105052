import React, { useEffect, useState } from 'react';

const ReceivedBookCampaignsTable = () => {
  const [receivedBookCampaigns, setReceivedBookCampaigns] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchReceivedBookCampaigns = async (page) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/received?page=${page}&limit=10`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch received book campaigns');
      }

      const data = await response.json();
      setReceivedBookCampaigns(data.data);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    } catch (err) {
      setError('Failed to fetch received book campaigns');
    }
  };

  useEffect(() => {
    fetchReceivedBookCampaigns(currentPage);
  }, [currentPage]);

  return (
    <div className="max-w-7xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Received Book Campaigns</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">Campaign Name</th>
              <th className="py-3 px-6 text-left">Quantity</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">User</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {receivedBookCampaigns.map((campaign) => (
              <tr key={campaign._id} className="bg-white">
                <td className="py-4 px-6">{campaign.name}</td>
                <td className="py-4 px-6">{campaign.quantity}</td>
                <td className="py-4 px-6">{campaign.description}</td>
                <td className="py-4 px-6">{campaign.userId?.firstname}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ReceivedBookCampaignsTable;
