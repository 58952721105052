import React, { useEffect} from "react";
import { Outlet,  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from '../UserComponent/Sidebar';
import Header from '../UserComponent/Header'

const UserLayouts = () => {

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user, navigate]);


    return (
        <>
            <div className="flex h-screen">
            <Sidebar />
            <div className="flex-1 ml-64 flex flex-col">
                <Header />
              <main className="flex-1 p-4 overflow-y-auto">
                <Outlet />
              </main>
            </div>
          </div>
        </>
    );

};

export default UserLayouts;
