import React from 'react';
import AFrican from '../images/AFrican.jpg';
import { Link } from 'react-router-dom';

const ShowBookCampaign = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 space-y-6 p-10">
      <div className="max-w-7xl flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="md:w-1/2">
          <img
            src={AFrican}
            alt="About Africa Education Assistance"
            className="object-cover w-full h-full rounded-lg shadow-lg"
          />
        </div>
        <div className="p-8 md:w-1/2 flex flex-col justify-center rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">Books Campaign</h2>
          <p className="text-gray-700 mb-4">
          Our book campaign aims to address the critical need for educational resources in rural Africa.
          Many students in these areas lack access to essential textbooks and reading materials, which hinders their learning
          and educational development. Through our campaign, we encourage individuals and students worldwide to donate their
          gently used books. These books are then distributed to schools and students in need, ensuring that every child has
          the opportunity to access quality education regardless of their economic circumstances.
          </p>
          <p className="text-gray-700 mb-6">
          The campaign not only focuses on collecting and distributing books but also on promoting literacy and a love for
          reading among young learners. By donating books through our platform, donors contribute directly to improving educational
          outcomes and empowering future generations in Africa. Together, we can make a significant impact on education and create
          a brighter future for children who deserve access to the tools and resources they need to succeed.
          </p>
          <Link to="bookscampaign"
            className="bg-white border-2 border-black text-black py-2 px-4 rounded-full hover:bg-blue-700 hover:border-white hover:text-white"
            onClick=""
          >
            Donate A Book Today
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ShowBookCampaign