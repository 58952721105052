import React, { useEffect, useState } from 'react';

const AllDonationsTable = () => {
  const [donations, setDonations] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAllDonations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/donations/all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch donations');
        }

        const data = await response.json();
        setDonations(data);
      } catch (err) {
        setError('Failed to fetch donations');
      }
    };

    fetchAllDonations();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">All Donations</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">First Name</th>
              <th className="py-3 px-6 text-left">Last Name</th>
              <th className="py-3 px-6 text-left">Donor Name</th>
              <th className="py-3 px-6 text-left">Amount</th>
              <th className="py-3 px-6 text-left">Currency</th>
              <th className="py-3 px-6 text-left">Date</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {donations.map((donation) => (
              <tr key={donation._id} className="bg-white">
                <td className="py-4 px-6">{donation.firstName}</td>
                <td className="py-4 px-6">{donation.lastName}</td>
                <td className="py-4 px-6">{donation.donorName}</td>
                <td className="py-4 px-6">{donation.amount}</td>
                <td className="py-4 px-6">{donation.currency}</td>
                <td className="py-4 px-6">{new Date(donation.createdAt).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllDonationsTable;
