import React, { useEffect, useState } from 'react';
import useProfile from '../useProfile.js';
import { Link } from 'react-router-dom';

const MainContent = () => {
  const userName = useProfile();

  const [counts, setCounts] = useState({
    bookCampaigns: 0,
    booksDonated: 0,
    schoolBuildings: 0,
    moniesRaised: 0,
  });

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [bookCampaignsRes, booksDonatedRes, schoolBuildingsRes, moniesRaisedRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/campaign/countUserBookCampaign`, { credentials: 'include' }),
          fetch(`${process.env.REACT_APP_API_URL}/api/donatebook/countUserBooksDonated`, { credentials: 'include' }),
          // fetch(`${process.env.REACT_APP_API_URL}/api/school-building/countSchoolBuildingsByUser`, { credentials: 'include' }),
          // fetch(`${process.env.REACT_APP_API_URL}/api/school-building/userMoniesRaised`, { credentials: 'include' })
        ]);

        const bookCampaigns = await bookCampaignsRes.json();
        const booksDonated = await booksDonatedRes.json();
        // const schoolBuildings = await schoolBuildingsRes.json();
        // const moniesRaised = await moniesRaisedRes.json();

        // console.log("School Buildings: ", schoolBuildings);

        // console.log("Monies Raised Value:", moniesRaised);
        // const moniesRaisedValue = parseInt(moniesRaised.total) || 0;

        setCounts({
          bookCampaigns: bookCampaigns.count,
          booksDonated: booksDonated.count,
          // schoolBuildings: schoolBuildings.count,
          // moniesRaised: moniesRaisedValue,
        });
      } catch (error) {
        console.error('Failed to fetch counts', error);
      }
    };

    fetchCounts();
  }, []);

  // const formattedMoniesRaised = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'GHS' // Ghanaian Cedi symbol
  // }).format(counts.moniesRaised);

  const cards = [
    {
      title: 'Books Campaign',
      description: `You have started ${counts.bookCampaigns} book campaigns.`,
    },
    {
      title: 'Books Donated',
      description: `You have ${counts.booksDonated} books received.`,
    },
    // {
    //   title: 'School Buildings Campaign',
    //   description: `You have started ${counts.schoolBuildings} school building campaigns.`,
    // },
    // {
    //   title: 'Monies Raised',
    //   description: `You have raised ${formattedMoniesRaised}.`,
    // },
  ];

  return (
    <div className="flex-1 p-4">
      <h2 className="text-2xl font-bold mb-4">Dashboard Overview</h2>
      <div className="bg-white p-4 shadow-md rounded-md">
        <p>Welcome to your dashboard <b>{userName}</b>.</p>
      </div>

      <div className="container py-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {cards.map((card, index) => (
            <Link
              key={index}
              to="#"
              className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {card.title}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                {card.description}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
