import React, { useState } from 'react';
import place from '../images/place.webp';

const About = () => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const togglePanel = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 space-y-6 p-8">
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden max-w-5xl">
        <div className="md:w-1/2">
          <img
            src={place}
            alt="About Help Another"
            className="object-cover w-full h-full rounded-lg shadow-lg"
          />
        </div>
        <div className="p-8 md:w-1/2 flex flex-col justify-center rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">About Us</h2>
          <p className="text-gray-700 mb-4">
            Welcome to Help Another, a dedicated platform committed to transforming the educational
            landscape in Africa, with a special focus on rural areas that face significant challenges in accessing quality education.
          </p>
          <p className="text-gray-700 mb-6">
            Our mission is to bridge the educational gap by leveraging technology, community support,
            and strategic partnerships to provide essential resources such as classrooms and books to those in need.
          </p>
          <button
            className="bg-white border-2 border-black text-black py-2 px-4 rounded-full hover:bg-gray-100"
            onClick={togglePanel}
          >
            Learn More About Us
          </button>
        </div>
      </div>

      {isPanelVisible && (
        <div className="mt-6 p-6 bg-white rounded-lg shadow-lg max-w-5xl">
          <h3 className="text-2xl font-bold mb-4">About Us</h3>
          <p className="text-gray-700 mb-4">
          Welcome to Africa Education Assistance, a dedicated platform committed to transforming the educational landscape in Africa,
          with a special focus on rural areas that face significant challenges in accessing quality education. Our mission is
          to bridge the educational gap by leveraging technology, community support, and strategic partnerships to provide
          essential resources such as classrooms and books to those in need.
          </p>
          <h3 className="text-2xl font-bold mb-4">Our Mission</h3>
          <p className="text-gray-700 mb-4">
          At Africa Education Assistance, we believe that every child deserves the opportunity to learn and thrive, regardless of their
          geographical location. Our mission is to create a supportive and collaborative environment where individuals and
          organizations worldwide can come together to make a lasting impact on education in rural Africa. By facilitating
          fundraising for classroom construction and enabling the donation of books, we aim to empower communities and foster
          a brighter future for the next generation.
          </p>
          <h3 className="text-2xl font-bold mb-4">What We Do</h3>
          <p className="text-gray-700 mb-4">
          1. Fundraising for Classroom Construction:<br/>
            We provide a platform where users can create and support fundraising campaigns to build classrooms in rural areas.
            Our goal is to ensure that every child has a conducive learning environment, complete with the necessary
            infrastructure to support their educational journey.
          </p>
          <p className="text-gray-700 mb-4">
          2. Book Donation System:<br/>
            Our platform enables students and individuals to donate books they no longer need to those who lack access to educational
            materials. By matching donors with recipients, we help distribute books efficiently and effectively, ensuring they reach
            the hands of those who need them the most.
          </p>
          <p className="text-gray-700 mb-4">
          3. Community and Volunteer Engagement:<br/>
            We believe in the power of community and volunteerism. Our platform offers opportunities for volunteers to participate in
            various activities, from organizing book drives to assisting with classroom construction projects. Together, we can make a difference.
          </p>
          <h3 className="text-2xl font-bold mb-4">Our Approach</h3>
          <p className="text-gray-700 mb-4">
          Technology and Innovation:
            We harness the power of technology to create a user-friendly platform that is accessible to all. From secure payment gateways to mobile compatibility,
            our innovative approach ensures that users can easily navigate the site, participate in campaigns, and track the impact of their contributions.
          </p>
          <p className="text-gray-700 mb-4">
          Partnerships and Collaboration:
            Collaboration is at the heart of our efforts. We work closely with non-governmental organizations (NGOs), local educational bodies, corporate sponsors,
            and government agencies to maximize our reach and effectiveness. By building strong partnerships, we ensure that resources are utilized efficiently and
            projects are sustainable.
          </p>
          <p className="text-gray-700 mb-4">
          Transparency and Accountability:
            We are committed to maintaining the highest standards of transparency and accountability. Donors can track the progress of their contributions and
            see the tangible impact of their support through regular updates, photos, and stories from beneficiaries. We believe that trust is fundamental to our mission,
            and we strive to build and maintain it through every step of our journey.
          </p>
          <h3 className="text-2xl font-bold mb-4">Our Impact</h3>
          <p className="text-gray-700 mb-4">
          Since our inception, Africa Education Assistance has made significant strides in improving education in rural Africa. We have successfully funded numerous classroom
          construction projects and facilitated the donation of thousands of books. Our efforts have empowered communities, provided children with better learning
          environments, and inspired a global movement of support for education.
          </p>
          <h3 className="text-2xl font-bold mb-4">Join Us</h3>
          <p className="text-gray-700 mb-4">
          We invite you to join us in our mission to transform education in rural Africa. Whether you are an individual looking to make a donation, a student wanting to
          donate books, or a corporation interested in partnering with us, your support is invaluable. Together, we can create a future where every child has the
          opportunity to learn and succeed.
          </p>
          <h3 className="text-2xl font-bold mb-4">Get Involved Today:</h3>
          <p className="text-gray-700 mb-4">
          Donate: Support our fundraising campaigns and help build classrooms.
          </p>
          <p className="text-gray-700 mb-4">
          Volunteer: Participate in our community initiatives and make a hands-on impact.
          </p>
          <p className="text-gray-700 mb-4">
          Partner: Collaborate with us to maximize our reach and effectiveness.
          </p>
          <p className="text-gray-700 mb-4">
          Thank you for visiting Africa Education Assistance. We look forward to working together to make a lasting difference in the lives of children and communities across Africa.
          </p>
        </div>
      )}
    </div>
  );
};

export default About;
