import React from 'react';
import { useNavigate } from 'react-router-dom';
import useProfile from '../useProfile.js';

const Header = () => {

  const userName = useProfile();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
          method: 'GET',
          credentials: 'include',
      });
      if (response.ok) {
          console.log('Admin Logout Successfully');
          navigate('/login');
      } else {
          console.error('Failed to logout');
      }
  } catch (error) {
      console.error("There was an error logging out!", error);
  }
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-end">
        <ul className="flex items-center space-x-4">
          <p className="text-white">{userName}</p>
          <li onClick={handleLogout} className="text-white cursor-pointer">Logout</li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
