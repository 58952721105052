import React, { useState, useEffect } from 'react';
import BooksCampaignCard from './BooksCampaignCard';

const BooksCampaign = () => {
  const [booksCampaign, setBooksCampaign] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBooksCampaign = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/getbookcampaigns`);
        const data = await response.json();

        console.log('Fetched data:', data);

        if (response.ok) {
          setBooksCampaign(data.data); // Assuming data is an array
        } else {
          setError(data.message || 'Failed to fetch books campaign');
        }
      } catch (err) {
        setError('Failed to fetch books campaign');
      }
    };

    fetchBooksCampaign();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-10 px-4">
      <h1 className="text-5xl font-bold mb-5 text-center pb-10">Books Campaign</h1>
      {/* {error && <div className="mb-4 text-red-500">{error}</div>} */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {booksCampaign.length > 0 ? (
          booksCampaign.map((campaign) => (
            <BooksCampaignCard key={campaign._id} campaign={campaign} />
          ))
        ) : (
          <p className="text-gray-700 p-5 text-center">No campaigns found.</p>
        )}
      </div>
    </div>
  );
};

export default BooksCampaign;
