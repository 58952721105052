import React, { useEffect, useState } from 'react';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState('');
  const [replyMessageId, setReplyMessageId] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [receiverMessage, setReceiverMessage] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages/all-messages`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setMessages(data);
        } else {
          console.error('Error fetching messages');
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const handleReply = (id, email, message) => {
    setReplyMessageId(id);
    setReceiverEmail(email);
    setReceiverMessage(message);
    setReplyModalOpen(true);
  };

  const handleReplySubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages/reply-message/${replyMessageId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ replyMessage }),
      });

      if (response.ok) {
        const updatedMessages = messages.map(message =>
          message._id === replyMessageId ? { ...message, reply: replyMessage } : message
        );
        setMessages(updatedMessages);
        setReplyModalOpen(false);
        setReplyMessage('');
      } else {
        console.error('Error replying to message:', response.statusText);
      }
    } catch (error) {
      console.error('Error replying to message:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/messages/delete-message/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const updatedMessages = messages.filter(message => message._id !== id);
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-semibold mb-4 py-5">Received Messages</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Full Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">Message</th>
            <th className="py-2 px-4 border-b">Date</th>
            <th className="py-2 px-4 border-b">Reply Status</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => (
            <tr key={message._id}>
              <td className="py-2 px-4 border-b">{message.fullName}</td>
              <td className="py-2 px-4 border-b">{message.email}</td>
              <td className="py-2 px-4 border-b">{message.message}</td>
              <td className="py-2 px-4 border-b">{new Date(message.createdAt).toLocaleString()}</td>
              <td className="py-2 px-4 border-b">
                {message.reply ? (
                  <div>
                    <p className="text-green-500">Replied</p>
                    <p className="text-sm text-gray-700 mt-1">{message.reply}</p>
                  </div>
                ) : (
                  <p className="text-red-500">Not Replied</p>
                )}
              </td>
              <td className="py-2 px-4 border-b">
                  <>
                    <button onClick={() => handleReply(message._id, message.email, message.message)} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-2 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
                      Reply
                    </button>
                    <button onClick={() => handleDelete(message._id)} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50">
                      Delete
                    </button>
                  </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {replyModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h1 className="text-lg font-medium text-gray-800 mb-4">Reply to Message</h1>

                <p className="text-sm mb-2"><strong>To:</strong> {receiverEmail}</p>
                <p className="text-sm mb-4"><strong>Subject:</strong> Re: {receiverMessage}</p>

                <textarea
                  className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  placeholder="Reply Message"
                  value={replyMessage}
                  onChange={(e) => setReplyMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={handleReplySubmit} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Reply
                </button>
                <button onClick={() => setReplyModalOpen(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Messages;
