import React, { useEffect, useState } from 'react';

const DeletedSchoolBuildingsTable = () => {
  const [deletedSchoolBuildings, setDeletedSchoolBuildings] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDeletedSchoolBuildings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/school-building/deleted`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch deleted school buildings');
        }

        const data = await response.json();
        setDeletedSchoolBuildings(data);
      } catch (err) {
        setError('Failed to fetch deleted school buildings');
      }
    };

    fetchDeletedSchoolBuildings();
  }, []);

  const handleRestore = async (buildingId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/school-building/restore/${buildingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to restore school building');
      }

      setDeletedSchoolBuildings((prevBuildings) =>
        prevBuildings.filter((building) => building._id !== buildingId)
      );

    } catch (err) {
      setError('Failed to restore school building');
    }
  };

  const handlePermanentDelete = async (buildingId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/school-building/permanent/${buildingId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to permanently delete school building');
      }

      setDeletedSchoolBuildings((prevBuildings) =>
        prevBuildings.filter((building) => building._id !== buildingId)
      );

    } catch (err) {
      setError('Failed to permanently delete school building');
    }
  };

  return (
    <div className="max-w-7xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Deleted School Building Campaigns</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">Campaign Name</th>
              <th className="py-3 px-6 text-left">Address</th>
              <th className="py-3 px-6 text-left">City</th>
              <th className="py-3 px-6 text-left">Region</th>
              <th className="py-3 px-6 text-left">Country</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {deletedSchoolBuildings.map((building) => (
              <tr key={building._id} className="bg-white">
                <td className="py-4 px-6">{building.name}</td>
                <td className="py-4 px-6">{building.address}</td>
                <td className="py-4 px-6">{building.city}</td>
                <td className="py-4 px-6">{building.region}</td>
                <td className="py-4 px-6">{building.country}</td>
                <td className="py-4 px-6 space-x-2">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => handleRestore(building._id)}
                  >
                    Restore
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handlePermanentDelete(building._id)}
                  >
                    Delete Permanently
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeletedSchoolBuildingsTable;
