import React, { useEffect, useState } from 'react';

const DeletedBookCampaignTable = () => {
  const [deletedBookCampaign, setDeletedBookCampaign] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDeletedBookCampaign = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/deleted`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch deleted Book Campaign');
        }

        const data = await response.json();
        setDeletedBookCampaign(data);
      } catch (err) {
        setError('Failed to fetch deleted Book Campaign');
      }
    };

    fetchDeletedBookCampaign();
  }, []);

  const handleRestore = async (buildingId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/restore/${buildingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to restore Book Campaign');
      }

      setDeletedBookCampaign((prevBuildings) =>
        prevBuildings.filter((building) => building._id !== buildingId)
      );

    } catch (err) {
      setError('Failed to restore Book Campaign');
    }
  };

  const handlePermanentDelete = async (campaignId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/permanent/${campaignId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to permanently delete Book Campaign');
      }

      setDeletedBookCampaign((prevBookCampaign) =>
        prevBookCampaign.filter((campaign) => campaign._id !== campaignId)
      );

    } catch (err) {
      setError('Failed to permanently delete Book Campaign');
    }
  };

  return (
    <div className="max-w-7xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Deleted Book Campaigns</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">Campaign Name</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">Quantity</th>
              <th className="py-3 px-6 text-left">Received</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {deletedBookCampaign.map((campaign) => (
              <tr key={campaign._id} className="bg-white">
                <td className="py-4 px-6">{campaign.name}</td>
                <td className="py-4 px-6">{campaign.description}</td>
                <td className="py-4 px-6">{campaign.quantity}</td>
                <td className="py-4 px-6">{campaign.received}</td>
                <td className="py-4 px-6 space-x-2">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => handleRestore(campaign._id)}
                  >
                    Restore
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handlePermanentDelete(campaign._id)}
                  >
                    Delete Permanently
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeletedBookCampaignTable;
