import React from 'react';
import Main from '../UserComponent/Main';


const User = () => {

  return (
    <div>
      <Main />
    </div>
  )
};

export default User