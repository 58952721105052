import './index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import User from './pages/User';
import Admin from './pages/Admin';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateUser } from './redux/AuthSlice';
import AdminLayouts from './layouts/AdminLayouts';
import UserLayouts from './layouts/UserLayouts';
import BooksCampaign from './pages/BooksCampaign';
import SchoolBuildingForm from './AdminComponent/SchoolBuildingForm';
import SchoolBuildingTable from './AdminComponent/SchoolBuildingsTable';
import Donate from './pages/Donate';
import Index from './pages/Index';
import About from './pages/About';
import VerifyDonation from './pages/VerifyDonation';
import DonateBook from './components/DonateBook';
import BookCampaign from './components/BookCampaign';
import SchoolBuildingDetail from './components/SchoolBuildingDetail';
import BookCampaignDetail from './components/BookCampaignDetail';
import SchoolBuildings from './components/SchoolBuildings';
import AllBooksCampaign from './components/AllBooksCampaign';
import UserSchoolBuildings from './UserComponent/UserSchoolBuildings';
import AllBooks from './AdminComponent/AllBooks';
import UserBooksCampaign from './UserComponent/UserBooksCampaign';
import DonationsTable from './UserComponent/DonationsTable';
import AllDonationsTable from './AdminComponent/AllDonationsTable';
import DonatedBooksTable from './UserComponent/DonatedBooksTable';
import AllBooksDonatedTable from './AdminComponent/AllBooksDonatedTable';
import UsersTable from './AdminComponent/UsersTable';
import DeletedSchoolBuildingsTable from './AdminComponent/DeletedSchoolBuildingsTable';
import ReceivedBookCampaignsTable from './AdminComponent/ReceivedBookCampaignsTable';
import CreateCampaign from './components/CreateCampaign';
import DeletedBookCampaignTable from './AdminComponent/DeletedBookCampaignTable';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Contact from './components/Contact';
import Messages from './AdminComponent/Messages';
import NotFound from './components/NotFound';

function App() {

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUser());
  }, [user, dispatch]);

  return (
    <Router>
      <Toaster />
      <div className="App">
        <Routes>

          <Route path='/admin' element={<AdminLayouts />}>
            <Route index element={<Admin />} />
            <Route path="createbookcampaign" element={<BookCampaign />} />
            <Route path="schoolbuildingform" element={<SchoolBuildingForm />} />
            <Route path="schoolbuildingtable" element={<SchoolBuildingTable />} />
            <Route path="alldonations" element={<AllDonationsTable />} />
            <Route path="allbooks" element={<AllBooks />} />
            <Route path="booksdonated" element={<AllBooksDonatedTable />} />
            <Route path="users" element={<UsersTable />} />
            <Route path="deleted" element={<DeletedSchoolBuildingsTable />} />
            <Route path="received" element={<ReceivedBookCampaignsTable />} />
            <Route path="booksdeleted" element={<DeletedBookCampaignTable />} />
            <Route path="messages" element={<Messages />} />
          </Route>

          <Route path='/user' element={<UserLayouts />}>
            <Route index element={<User />} />
            <Route path="createbookcampaign" element={<BookCampaign />} />
            {/* <Route path="schoolbuildingform" element={<SchoolBuildingForm />} /> */}
            <Route path="bookscampaign" element={<UserBooksCampaign />} />
            {/* <Route path="schoolbuildings" element={<UserSchoolBuildings />} />
            <Route path="fundsdonated" element={<DonationsTable />} /> */}
            <Route path="booksdonated" element={<DonatedBooksTable />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="*" element={<NotFound />} /> */}

          <Route path="/" element={<Index />} >
            <Route path="about" element={<About />} />
            {/* <Route path="donate/:id" element={<Donate />} /> */}
            {/* <Route path="verify-donation" element={<VerifyDonation />} /> */}
            <Route path="/donatebook/:bookCampaignId" element={<DonateBook />} />
            {/* <Route path="/school-building/:id" element={<SchoolBuildingDetail />} /> */}
            <Route path="/campaign/:id" element={<BookCampaignDetail />} />
            {/* <Route path="/schoolbuilding" element={<SchoolBuildings/>} /> */}
            <Route path="/bookscampaign" element={<AllBooksCampaign/>} />
            <Route path="/create-campaign" element={<CreateCampaign />} />
            <Route path="/create/bookcampaign" element={<BookCampaign />} />
            {/* <Route path="/create/schoolbuilding" element={<SchoolBuildingForm />} /> */}
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Route>

        </Routes>
      </div>
    </Router>
  );
}

export default App;
