import React from 'react';
import { Link } from 'react-router-dom';
import errorimg from '../images/errorimg.png';

const BooksCampaignCard = ({ campaign }) => {
  if (!campaign) {
    return null;
  }

  const truncateText = (text, maxWords) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > maxWords ? words.slice(0, maxWords).join(' ') + '...' : text;
  };

  const imageUrl = campaign.picture ? `${process.env.REACT_APP_API_URL}/${campaign.picture}` : errorimg; // fallback image path

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
      <img
        src={imageUrl}
        alt={campaign.name}
        className="w-full h-48 object-contain"
      />
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2">
          <Link to={`/campaign/${campaign._id}`} className="text-blue-700 hover:underline">
            {campaign.name}
          </Link>
        </h2>
        <p className="text-gray-700 mb-2">{truncateText(campaign.description, 50)}</p>
        <p className="text-gray-700 mb-2"><strong>Target:</strong> {campaign.quantity}</p>
        <p className="text-gray-700 mb-2"><strong>Received:</strong> {campaign.received ? 'Yes' : 'No'}</p>
        {campaign.userId ? (
          <span className="text-gray-700 mb-2"><strong>By:</strong> {campaign.userId.firstname}</span>
        ) : (
          <span className="text-gray-700 mb-2"><strong>By:</strong> Unknown</span>
        )}
        <br />
        <br />
        <Link
          to={`/donatebook/${campaign._id}`}
          className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none ${
            campaign.received
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
          }`}
            onClick={(e) => {
              if (campaign.received) {
                e.preventDefault();
              }
            }}
          >
          Donate Book
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default BooksCampaignCard;
