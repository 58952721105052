import React from 'react';
import Main from '../AdminComponent/Main';
// import useAuthRedirect from '../useAuthRedirect.js';

const Admin = () => {

  // useAuthRedirect();

  return (
    <>
      <Main />
    </>
  )

};

export default Admin
