import React from 'react';
import { useNavigate } from 'react-router-dom';
import useProfile from '../useProfile';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/AuthSlice';

const NavBar = () => {
    const navigate = useNavigate();
    const userName = useProfile();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                dispatch(logout());
                // console.log('User Logout Successfully');
                navigate('/login');
            } else {
                console.error('Failed to logout');
            }
        } catch (error) {
            console.error("There was an error logging out!", error);
        }
    };

    return (
        <nav className="bg-gray-800 p-4">
            <div className="container mx-auto flex justify-end">
                <div className="flex items-center py-4">
                    {/* <a href="/home" className="text-white text-lg font-bold">Home</a> */}
                    <ul className="flex items-center space-x-4">
                        <p className="text-white">{userName}</p>
                        <li onClick={handleLogout} className="text-white cursor-pointer">Logout</li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
