import React, { useEffect, useState } from 'react';

const DonatedBooksTable = () => {
  const [donatedBooks, setDonatedBooks] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDonatedBooks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/donatebook/getDonatedBooksByUser/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch donated books');
        }

        const data = await response.json();
        setDonatedBooks(data);
      } catch (err) {
        setError('Failed to fetch donated books');
      }
    };

    fetchDonatedBooks();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Donated Books</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">Campaign Name</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Quantity</th>
              <th className="py-3 px-6 text-left">Status</th>
              <th className="py-3 px-6 text-left">Phone Number</th>
              <th className="py-3 px-6 text-left">Donate Date</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {donatedBooks.map((book) => (
              <tr key={book._id} className="bg-white">
                <td className="py-4 px-6">{book.bookCampaignId.name}</td>
                <td className="py-4 px-6">{book.name}</td>
                <td className="py-4 px-6">{book.quantity}</td>
                <td className="py-4 px-6">{book.status}</td>
                <td className="py-4 px-6">{book.phonenumber}</td>
                <td className="py-4 px-6">{new Date(book.donateDate).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DonatedBooksTable;
