import React from 'react';
import Vision from '../components/Vision';

const About = () => {
  return (
    <>
      <Vision />
    </>
  )
}

export default About