import React from 'react';
import place from '../images/place.webp';
import { Link } from 'react-router-dom';

const ShowAboutCampaign = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 space-y-6 p-10">
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden max-w-7xl">
        <div className="md:w-1/2">
          <img
            src={place}
            alt="About Africa Education Assistance"
            className="object-cover w-full h-full rounded-lg shadow-lg"
          />
        </div>
        <div className="p-8 md:w-1/2 flex flex-col justify-center rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">About Us</h2>
          <p className="text-gray-700 mb-4">
            Welcome to Africa Education Assistance, a dedicated platform committed to transforming the educational
            landscape in Africa, with a special focus on rural areas that face significant challenges in accessing quality education.
          </p>
          <p className="text-gray-700 mb-6">
            Our mission is to bridge the educational gap by leveraging technology, community support,
            and strategic partnerships to provide essential resources such as classrooms and books to those in need.
          </p>
          <Link to="about"
            className="bg-white border-2 border-black text-black py-2 px-4 rounded-full hover:bg-blue-700 hover:border-white hover:text-white"
            onClick=""
          >
            Learn More About Us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ShowAboutCampaign