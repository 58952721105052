import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useProfile from '../useProfile.js';

const Navbar = () => {
  const userName = useProfile();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDashboardRedirect = () => {
    // console.log('User:', user);
    if (user) {
      // console.log('User role:', user);
      if (user.user.role === 'admin') {
        // console.log('Navigating to admin dashboard');
        navigate('admin');
      } else if (user.user.role === 'user') {
        // console.log('Navigating to user dashboard');
        navigate('user');
      }
    }
  };

  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsMenuOpen(false);
  };

  const handleCreateCampaignClick = () => {
    setIsMenuOpen(false);
    if (user) {
      navigate('/create-campaign');
    } else {
      navigate('/login', { state: { from: '/create-campaign' } });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
        <Link to="/" className="flex items-center px-5 space-x-3 rtl:space-x-reverse">
          <img src="https://flowbite.com/docs/images/logo.svg" className="h-8" alt="EduAssist Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">HelpAnother</span>
        </Link>
        <div className="flex md:hidden">
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 5a1 1 0 012 0h10a1 1 0 110 2H5a1 1 0 01-2 0zm0 4a1 1 0 012 0h10a1 1 0 110 2H5a1 1 0 01-2 0zm0 4a1 1 0 012 0h10a1 1 0 110 2H5a1 1 0 01-2 0z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <div className={`w-full py-2 md:flex md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`}>
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li className="py-2">
              <Link
                to="/"
                className={`block py-2 px-3 ${activeLink === '/' ? 'text-blue-700' : 'text-gray-900'} rounded md:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                aria-current="page"
                onClick={() => handleLinkClick('/')}
              >
                Home
              </Link>
            </li>
            <li className="py-2">
              <Link
                to="/about"
                className={`block py-2 px-3 ${activeLink === '/about' ? 'text-blue-700' : 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                onClick={() => handleLinkClick('/about')}
              >
                About
              </Link>
            </li>
            <li className="py-2">
              <Link
                to="/bookscampaign"
                className={`block py-2 px-3 ${activeLink === '/bookscampaign' ? 'text-blue-700' : 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                onClick={() => handleLinkClick('/bookscampaign')}
              >
                Books Campaign
              </Link>
            </li>
            {/* <li>
              <Link
                to="/schoolbuilding"
                className={`block py-2 px-3 ${activeLink === '/schoolbuilding' ? 'text-blue-700' : 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                onClick={() => handleLinkClick('/schoolbuilding')}
              >
                School Buildings
              </Link>
            </li> */}
            <li className="py-2">
              <Link
                to="/contact"
                className={`block py-2 px-3 ${activeLink === '/contact' ? 'text-blue-700' : 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                onClick={() => handleLinkClick('/contact')}
              >
                Contact
              </Link>
            </li>
            <li className="px-2 py-2 border border-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700">
              <span
                className={`block py-2 px-3 text-gray-900 rounded ${activeLink === '/create-campaign' ? 'text-blue-700' : 'hover:bg-gray-100 md:hover:bg-transparent'} md:p-0 md:dark:hover:text-white dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 cursor-pointer`}
                onClick={handleCreateCampaignClick}
              >
                Create A Campaign
              </span>
            </li>
            <li className="pl-2 py-2">
              {user ? (
                <span
                  onClick={handleDashboardRedirect}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 cursor-pointer"
                >
                  {userName}
                </span>
              ) : (
                <Link to="/login" className={`block py-2 px-3 ${activeLink === '/login' ? 'text-blue-700' : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700'} md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`} onClick={() => handleLinkClick('/login')}>
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
