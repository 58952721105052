import React from 'react';
import { useNavigate } from 'react-router-dom';
import place from '../images/education.jpg';
import build from '../images/AFrican.jpg';

const CreateCampaign = () => {
  const navigate = useNavigate();

  const handleCreateBookCampaign = () => {
    navigate('/create/bookcampaign');
  };

  // const handleCreateSchoolBuildingCampaign = () => {
  //   navigate('/create/schoolbuilding');
  // };

  return (
    // <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-10">
    //   <div className="container mx-auto px-4 py-8">
    //     <h1 className="text-5xl font-bold mb-4 text-center text-black">Create A Campaign</h1>
    //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    //       <div className="flex flex-col items-center justify-center bg-cover bg-center rounded-lg p-6 shadow-md h-96" style={{ backgroundImage: `url(${build})` }}>
    //         <div className="bg-black bg-opacity-50 p-4 rounded-md">
    //           <h2 className="text-3xl font-bold text-center text-white mb-4">Book Campaign</h2>
    //           <p className="text-white mb-4 font-medium">
    //             Welcome to Africa Education Assistance, a dedicated platform committed to transforming the educational
    //             landscape in Africa, with a special focus on rural areas that face significant challenges in accessing quality education.
    //           </p>
    //           <button
    //             onClick={handleCreateBookCampaign}
    //             className="w-full px-6 py-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none text-center"
    //           >
    //             Create Book Campaign
    //           </button>
    //         </div>
    //       </div>
    //       <div className="flex flex-col items-center justify-center bg-cover bg-center rounded-lg p-6 shadow-md h-96" style={{ backgroundImage: `url(${place})` }}>
    //         <div className="bg-black bg-opacity-50 p-4 rounded-md">
    //           <h2 className="text-3xl font-bold text-center text-white mb-4">School Building Campaign</h2>
    //           <p className="text-white mb-4 font-medium">
    //             Welcome to Africa Education Assistance, a dedicated platform committed to transforming the educational
    //             landscape in Africa, with a special focus on rural areas that face significant challenges in accessing quality education.
    //           </p>
    //           <button
    //             onClick={handleCreateSchoolBuildingCampaign}
    //             className="w-full px-6 py-4 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none text-center"
    //           >
    //             Create School Building Campaign
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-10">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-5xl font-bold mb-4 text-center text-black py-5">Create A Campaign</h1>
        <div className="flex justify-center">
          <div
            className="flex flex-col items-center justify-center bg-cover bg-center rounded-lg p-6 shadow-md h-96 w-full max-w-md"
            style={{ backgroundImage: `url(${build})` }}
          >
            <div className="bg-black bg-opacity-50 p-4 rounded-md text-center">
              <h2 className="text-3xl font-bold text-white mb-4">Book Campaign</h2>
              <p className="text-white mb-4 font-medium">
                Welcome to Africa Education Assistance, a dedicated platform committed to transforming the educational
                landscape in Africa, with a special focus on rural areas that face significant challenges in accessing quality education.
              </p>
              <button
                onClick={handleCreateBookCampaign}
                className="w-full px-6 py-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
              >
                Create Book Campaign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default CreateCampaign;
