import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from '../AdminComponent/Sidebar';
import Header from '../AdminComponent/Header';

const AdminLayouts = () => {

  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.auth);

  const { user } = useSelector((state) => {
        // console.log(state);
        return state.auth}
    );

  useEffect(() => {
    if (!user && user.role !== "admin") {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 ml-64 flex flex-col">
        <Header />
        <main className="flex-1 p-4 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );

};

export default AdminLayouts;
