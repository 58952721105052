import React, { useState } from 'react';
import useProfile from '../useProfile.js';
import { Link } from 'react-router-dom';
import {
  HomeIcon,
  ChartPieIcon,
  LibraryIcon,
  AcademicCapIcon,
  UsersIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  InboxIcon,
} from '@heroicons/react/outline';

const Sidebar = () => {
  const userName = useProfile();
  const [isCampaignsOpen, setIsCampaignsOpen] = useState(false);
  const [isDonationsOpen, setIsDonationsOpen] = useState(false);

  const toggleCampaigns = () => setIsCampaignsOpen(!isCampaignsOpen);
  const toggleDonations = () => setIsDonationsOpen(!isDonationsOpen);

  return (
    <div className="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white flex flex-col justify-between">
      <div className="p-4 overflow-y-auto">
        <h2 className="text-xl font-bold">EduAssist</h2>
        <ul className="mt-4">
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <HomeIcon className="w-6 h-6 mr-2" />
            <Link to="/admin">Dashboard</Link>
          </li>
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <ChartPieIcon className="w-6 h-6 mr-2" />
            Analytics
          </li>
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <InboxIcon className="w-6 h-6 mr-2" />
            <Link to="/admin/messages">Messages</Link>
          </li>
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center justify-between" onClick={toggleCampaigns}>
            <div className="flex items-center">
              <LibraryIcon className="w-6 h-6 mr-2" />
              Campaigns
            </div>
            {isCampaignsOpen ? (
              <ChevronDownIcon className="w-6 h-6" />
            ) : (
              <ChevronRightIcon className="w-6 h-6" />
            )}
          </li>
          {isCampaignsOpen && (
            <ul className="ml-8 mt-2">
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/createbookcampaign">Create Book Campaign</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/allbooks">Book Campaigns</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/schoolbuildingform">Create Buildings Campaign</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/schoolbuildingtable">Buildings Campaign</Link>
              </li>
              {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/fundscampaign">Funds Campaign</Link>
              </li> */}
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/deleted">School Campaigns Deleted</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/booksdeleted">Book Campaigns Deleted</Link>
              </li>
              {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/received">Campaigns Received</Link>
              </li> */}
              {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/campaignsactive">Active Campaigns</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/campaignscompleted">Completed Campaigns</Link>
              </li> */}
            </ul>
          )}
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center justify-between" onClick={toggleDonations}>
            <div className="flex items-center">
              <AcademicCapIcon className="w-6 h-6 mr-2" />
              Donations
            </div>
            {isDonationsOpen ? (
              <ChevronDownIcon className="w-6 h-6" />
            ) : (
              <ChevronRightIcon className="w-6 h-6" />
            )}
          </li>
          {isDonationsOpen && (
            <ul className="ml-8 mt-2">
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/booksdonated">Books Donated</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/admin/alldonations">Funds Donated</Link>
              </li>
            </ul>
          )}
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <UsersIcon className="w-6 h-6 mr-2" />
            <Link to="/admin/users">users</Link>
          </li>
        </ul>
      </div>
      <div className="p-4">
        <p className="text-md">Logged in as {userName}</p>
      </div>
    </div>
  );
};

export default Sidebar;
