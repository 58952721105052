import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { setUser } from '../redux/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAuthRedirect from '../useAuthRedirect';

const Login = () => {

    useAuthRedirect();

    const user = useSelector((state) => state.auth.user);
    // console.log("Use Selector: ", user);

    // const { user } = useSelector((state) => {
    //     console.log(state);
    //     return state.auth}
    // );

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     console.log('Location state:', location.state);
    // }, [location]);

    // useEffect(() => {
    //     console.log('Current user state:', user);
    // }, [user]);

    const { email, password } = formData;

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });

            if (!res.ok) {
                const errorData = await res.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await res.json();
            // console.log("Logged In Details: ",data);

            if (data && data.existingUser) {

                dispatch(setUser(data.existingUser));
                // console.log('User state after login:', user);

                toast.success('Login Successful', {
                    position: 'top-center',
                });

                const redirectTo = location.state?.from || (data.existingUser.role === 'admin' ? '/admin' : '/user');
                // console.log('Redirecting to:', redirectTo);
                navigate(redirectTo, { replace: true });
                toast.success(data.existingUser.role === 'admin' ? 'Admin Page' : 'User Page', {
                    position: 'top-center',
                });

            } else {
                // console.log('Invalid response structure:', data);
                throw new Error('Invalid response structure');
            }

        } catch (error) {
            console.log('Request error:', error);
            // setError(error.message);
        }
    };

    const navigateToForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-3 bg-white rounded shadow-md">
                <h1 className="text-2xl font-bold text-center">Login</h1>
                <form className="space-y-6" onSubmit={onSubmit}>
                    <div className="flex flex-col space-y-1">
                        <label htmlFor="email" className="text-sm font-semibold text-gray-600">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={onChange}
                            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
                            required
                        />
                    </div>
                    <div className="flex flex-col space-y-1">
                        <label htmlFor="password" className="text-sm font-semibold text-gray-600">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={onChange}
                            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
                            required
                        />
                    </div>
                    <button type="submit" className="w-full px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-400">
                        Login
                    </button>
                    <button
                        type="button"
                        onClick={navigateToForgotPassword}
                        className="w-full px-4 py-2 border border-gray-500 rounded-md text-gray-500 hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-400"
                    >
                        Forgot Password?
                    </button>
                    {/* {error && <p className="text-red-500 text-center">{error}</p>} */}
                    <div className="flex flex-col space-y-1">
                        <p>Don't have an account?
                            <Link className="px-2 py-2 hover:underline text-indigo-500 focus:outline-none" to="/register">Sign up</Link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
