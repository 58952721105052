import React from 'react';
import { Link } from 'react-router-dom';
import bg from '../images/bg.jpg';

const Banner = () => {
  return (
    <section className="bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-6xl">Empowering Rural Education</h1>
        <p className="mb-8 text-lg font-normal text-black lg:text-xl sm:px-16 lg:px-48">
          We aim at enhancing the education system in Africa, particularly in rural areas lacking classrooms and books.
          The platform will facilitate fundraising for building classrooms and enable the donation of books from students who no longer need them.
        </p>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <Link
            to="contact"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-black hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
          >
            Contact Us
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
          <Link
            to="bookscampaign"
            className="inline-flex justify-center hover:text-white items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-black rounded-lg border border-black hover:bg-black focus:ring-4 focus:ring-black"
          >
            Book Campaign
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Banner;
