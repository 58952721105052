import React, { useState } from 'react';
import useProfile from '../useProfile.js';
import { Link } from 'react-router-dom'
import {
  HomeIcon,
  LibraryIcon,
  AcademicCapIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';

const Sidebar = () => {
  const userName = useProfile();
  const [isCampaignsOpen, setIsCampaignsOpen] = useState(false);
  const [isDonationsOpen, setIsDonationsOpen] = useState(false);

  const toggleCampaigns = () => setIsCampaignsOpen(!isCampaignsOpen);
  const toggleDonations = () => setIsDonationsOpen(!isDonationsOpen);

  return (
    <div className="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white flex flex-col justify-between">
      <div className="p-4 overflow-y-auto">
        <h2 className="text-xl font-bold">EduAssist</h2>
        <ul className="mt-4">
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <HomeIcon className="w-6 h-6 mr-2" />
            <Link to="/user">Dashboard</Link>
          </li>
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center justify-between" onClick={toggleCampaigns}>
            <div className="flex items-center">
              <LibraryIcon className="w-6 h-6 mr-2" />
              Campaigns
            </div>
            {isCampaignsOpen ? (
              <ChevronDownIcon className="w-6 h-6" />
            ) : (
              <ChevronRightIcon className="w-6 h-6" />
            )}
          </li>
          {isCampaignsOpen && (
            <ul className="ml-8 mt-2">
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/createbookcampaign">Create Book Campaign</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/bookscampaign">Book Campaign</Link>
              </li>
              {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/schoolbuildingform">Create Buildings Campaign</Link>
              </li>
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/schoolbuildings">Buildings Campaign</Link>
              </li> */}
            </ul>
          )}
          <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center justify-between" onClick={toggleDonations}>
            <div className="flex items-center">
              <AcademicCapIcon className="w-6 h-6 mr-2" />
              Donations
            </div>
            {isDonationsOpen ? (
              <ChevronDownIcon className="w-6 h-6" />
            ) : (
              <ChevronRightIcon className="w-6 h-6" />
            )}
          </li>
          {isDonationsOpen && (
            <ul className="ml-8 mt-2">
              <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/booksdonated">Books Donated</Link>
              </li>
              {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
                <Link to="/user/fundsdonated">Funds Donated</Link>
              </li> */}
            </ul>
          )}
          {/* <li className="p-3 hover:bg-gray-700 cursor-pointer flex items-center">
            <UsersIcon className="w-6 h-6 mr-2" />
            <a href="/user/users">Users</a>
          </li> */}
        </ul>
      </div>
      <div className="p-4">
        <p className="text-md">Logged in as {userName}</p>
      </div>
    </div>
  );
};

export default Sidebar;
